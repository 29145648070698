export default [
  {
    key: 'nameKm',
    label: 'field.nameKm',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'code',
    label: 'field.code',
    rules: 'required|max:100',
    type: 'text',
    description: 'description.outletSegmentCode',
  },
  {
    key: 'channel',
    label: 'field.channel',
    rules: 'required',
    type: 'radio',
    options: [
      { text: 'field.onPremise', value: '1' },
      { text: 'field.offPremise', value: '2' },
    ],
    cols: "auto",
  },
  {
    hide: true,
    skip: true,
    cols: 12,
  },
  {
    key: 'isEnable',
    label: 'field.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
